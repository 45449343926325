import { FC } from 'react';
import { Option } from '../../Option';
import Button, { ButtonType, ButtonSize } from './Button';

type ButtonGroupProps = {
  selectedId?: string;
  className?: string;
  buttons: Array<Option<string, string>>;
  disabled?: boolean;
  onClick?: (option: Option<string, string>) => void;
  size?: ButtonSize;
};

export const ButtonGroup: FC<ButtonGroupProps> = (props) => {
  const { className, buttons, disabled, selectedId, onClick, size = ButtonSize.M } = props;

  const onItemClick = (item: Option<string, string>) => {
    onClick && onClick(item);
  };

  return (
    <div className={className} data-testid="button-group" data-cy="button-group">
      {buttons.map((item, i) => {
        let borderClass = 'rounded-none';
        if (i === 0) {
          borderClass = 'rounded-l-lg rounded-r-none';
        }
        if (i === buttons.length - 1) {
          borderClass = 'rounded-r-lg rounded-l-none';
        }
        return (
          <Button
            data-cy={`button-${item.id}`}
            disabled={disabled}
            id={item.id}
            className="mr-1 [[data-cy='action-wrapper']:hover_&[data-toggle-enabled='false']]:bg-white"
            borderClass={borderClass}
            key={item.id}
            size={size}
            type={ButtonType.TOGGLE}
            toggleEnabled={item.id === selectedId}
            onClick={() => onItemClick(item)}
            ignoreMinWidth={true}
          >
            {item.text}
          </Button>
        );
      })}
    </div>
  );
};
