import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { Option } from '../../../Option';
import { ButtonGroup } from '../../../shared/form-control/ButtonGroup';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';

type YesNoData = {
  enableNA?: boolean;
};
type YesNoProps = ActionBaseProps<Option<'yes' | 'no' | 'na', string>, YesNoData>;

const YesNoAction: FC<YesNoProps> = (props) => {
  const { response, data, required } = props;
  const { enableNA } = data;
  const { onAnswer, readOnly } = useFormAction(props);
  const { t } = useTranslation('activity-type');

  const onClick = useCallback(
    (newValue: Option<string, string> | null) => {
      if (newValue?.id === response?.id) {
        newValue = null;
      }

      onAnswer(newValue as Option<'yes' | 'no' | 'na', string>);
    },
    [response?.id, onAnswer],
  );

  const buttons = useMemo(() => {
    const result = [
      { id: 'yes', text: t('yes-no.yes'), value: 'Yes' },
      { id: 'no', text: t('yes-no.no'), value: 'No' },
    ];

    if (enableNA) {
      result.push({
        id: 'na',
        text: t('yes-no.not-applicable'),
        value: 'N/A',
      });
    }

    return result;
  }, [enableNA, t]);

  return (
    <div data-cy="yes-no-action">
      <ActionTitleDescription required={required} {...data} />
      <div className="my-4">
        <ButtonGroup onClick={onClick} disabled={readOnly} selectedId={response?.id} buttons={buttons} />
      </div>
    </div>
  );
};

export default YesNoAction;
